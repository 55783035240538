//@ts-ignore
import { createSignal } from "solid-js";
export const [globalBackground, setGlobalBackground] = createSignal<string>("");
export const [globalStream, setGlobalStream] = createSignal<string>("");
export const [globalStreamType, setGlobalStreamType] = createSignal<string>("");
export const [isPlaying, setIsPlaying] = createSignal<boolean>(true);
export const [isLoading, setIsLoading] = createSignal<boolean>(false);
export const [playListUrl, setPlayListUrl] = createSignal<string>("");
export const [playListUsername, setPlayListUsername] = createSignal<string>("");
export const [playListPassword, setPlayListPassword] = createSignal<string>("");
export const [isVpnMode, setIsVpnMode] = createSignal<boolean>(false);
export const [isBuffering, setIsBuffering] = createSignal<boolean>(false);
export const [playListName, setPlayListName] = createSignal<string>("");
export const [hidePlaylistInfo, setHidePlaylistInfo] = createSignal<string>("");
export const [lastStream, setLastStream] = createSignal<any>(null);
