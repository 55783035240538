import { createSignal, createEffect, createMemo, onCleanup, Show } from "solid-js";
import { For } from "solid-js";
import { Text, View, hexColor, setActiveElement } from "@lightningtv/solid";
import { Button, ProgressBar } from "@lightningtv/solid-ui";
import { useParams, useLocation } from "@solidjs/router";
import {
  isBuffering,
  playListPassword,
  playListUrl,
  playListUsername,
  setGlobalBackground,
  setGlobalStream,
  setGlobalStreamType,
  setIsBuffering,
  setIsPlaying,
} from "../../../state";
import axios from "axios";
import { useNavigate } from "@solidjs/router";
import OSD from "./Osd";
import videoPlayer from "../../videoPreview";
//import videoPlayer from "../../video"; // Kjo eshte per samsung AVPlayer
import LoadingIndicator from "../loading/loading";
import { Buffer } from "buffer";

const LiveTvPage = () => {
  const navigate = useNavigate();
  const [data, setData] = createSignal([]);
  const [liveChannel, setLiveChannel] = createSignal<any>({});
  const [currentIndex, setCurrentIndex] = createSignal(0);
  // const [channelMenu, setChannelMenu] = createSignal(false);
  const [currentStream, setCurrentStream] = createSignal();
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [showOSD, setShowOSD] = createSignal(false);
  const [osdQueue, setOsdQueue] = createSignal([]);
  let timeoutId;

  const location = useLocation();
  console.log("Current path:", location.pathname);

  function getQueryParams(search) {
    return new URLSearchParams(search);
  }

  createEffect(() => {
    const params: any = getQueryParams(location.search);
    const num = parseInt(params.get("num"), 10);

    // if (videoPlayer) {
    //   videoPlayer.destroy();
    // }

    const result: any = data().find((stream: any) => stream.stream_id === num);

    if (result) {
      console.log("Stream found:", result);
      const updatetNumber = result.num;
      setCurrentStream(result.stream_id);
      setCurrentIndex(updatetNumber - 1);
    } else {
      console.log("Stream not found");
    }
  });

  // createEffect(() => {
  //   const num = liveTvStreamID();
  //   if (num === null) {
  //     return;
  //   }

  //   // if (videoPlayer) {
  //   //   videoPlayer.destroy();
  //   // }

  //   console.log("hyn");

  //   const result: any = data().find((stream: any) => stream.stream_id === num);

  //   if (result) {
  //     console.log("Stream found:", result);
  //     const updatetNumber = result.num;
  //     setCurrentStream(result.stream_id);
  //     setCurrentIndex(updatetNumber - 1);
  //     // setTimeout(() => {
  //     //   setLiveTvStreamID(null);
  //     // }, 1000)
  //   } else {
  //     console.log("Stream not found");
  //   }
  // }, [liveTvStreamID()]);

  createEffect(() => {
    const username = playListUsername();
    const password = playListPassword();
    const playList = playListUrl();

    axios
      .get(`${playList}/player_api.php?username=${username}&password=${password}&action=get_live_streams`)
      .then(response => setData(response.data))
      .catch(error => console.error(error));
  });

  createEffect(() => {
    const index = currentIndex();
    const channelData = data();

    if (channelData.length > 0 && index >= 0 && index < channelData.length) {
      setLiveChannel(channelData[index]);
      console.log("Live Channel Updated: ", channelData[index]);
    }
  });

  const incrementIndex = async () => {
    const newIndex = currentIndex() + 1;
    console.log("mariza", newIndex);

    if (newIndex < data().length) {
      // Update the current index and live channel immediately
      setCurrentIndex(newIndex);
      setLiveChannel(data()[newIndex]);

      // Add the new channel number to the OSD queue
      setOsdQueue(prevQueue => [...prevQueue, data()[newIndex].num]); // Add current channel number

      // Show the OSD for the current channel
      setShowOSD(true); // Show OSD immediately

      // Load the new stream in the background
      loadNewStream(data()[newIndex].stream_id);
      console.log("mariza 2", data()[newIndex].stream_id);

      setIsBuffering(true);

      // Reset OSD timeout to hide after a duration
      resetOSDTimeout();
    }
  };

  const decrementIndex = async () => {
    const newIndex = currentIndex() - 1;
    if (newIndex >= 0) {
      // Update the current index and live channel immediately
      setCurrentIndex(newIndex);
      setLiveChannel(data()[newIndex]);

      // Add the new channel number to the OSD queue
      setOsdQueue(prevQueue => [...prevQueue, data()[newIndex].num]); // Add current channel number

      // Show the OSD for the current channel
      setShowOSD(true); // Show OSD immediately

      // Load the new stream in the background
      loadNewStream(data()[newIndex].stream_id);
      setIsBuffering(true);

      // Reset OSD timeout to hide after a duration
      resetOSDTimeout();
    }
  };

  createEffect(() => {
    // If the OSD queue has messages, display the last one in the queue
    if (osdQueue().length > 0) {
      const currentChannel = osdQueue()[osdQueue().length - 1];
      setShowOSD(true);
      console.log(`Current Channel: ${currentChannel}`);
    }
  });

  const loadNewStream = async streamId => {
    // Stop the current stream if necessary
    if (videoPlayer) {
      await videoPlayer.destroy(); // Consider removing this if you want to allow multiple streams to load simultaneously
    }

    // Update the current stream with the new stream ID
    setCurrentStream(streamId);

    // Play the new stream
    const streamUrl = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${streamId}.ts`;
    const streamUrlProxy = Buffer.from(
      `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/` + streamId + ".ts",
      "utf-8",
    ).toString("base64");
    if (localStorage.getItem("vpn") == "on") {
      if (localStorage.getItem("proxy_url")) {
        await playCurrentStream(localStorage.getItem("proxy_url") + streamUrlProxy);
      } else {
        await playCurrentStream(streamUrl);
      }
    } else {
      await playCurrentStream(streamUrl);
    }
  };

  const playCurrentStream = async streamUrl => {
    try {
      // await videoPlayer.open(streamUrl);
      videoPlayer.destroy();
      let context = "background";

      // videoPlayer.play(streamUrl , 'preview');
      videoPlayer
        .playVideo(streamUrl, context)
        .then(() => {
          console.log("Video is now playing.");
        })
        .catch(error => {
          console.error("Error playing video:", error);
        });
      // videoPlayer.play(); // Start playing
      setIsVideoPlaying(true);
      console.log("Video is now playing.");
    } catch (error) {
      console.error("Error playing video:", error);
      setIsVideoPlaying(false);
    }
  };

  function onCustomEscape() {
    // setLiveTvStreamID(null);

    // if (isVideoPlaying() === true) {
    // videoPlayer
    //   .destroy()
    //   .then(() => {
    //     console.log("Video player destroyed and video element removed.");
    //     navigate(-1);
    //   })
    //   .catch(error => {
    //     console.error("Error removing video player:", error);
    //   });
    videoPlayer.setSmallScreen();
    navigate(-1);
    //   setIsVideoPlaying(false);
    // } else {
    //   setIsVideoPlaying(false);
    // }
  }

  async function onEnterTrailer(context: any) {
    const streamOfID = currentStream();
    const videoUrl = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${streamOfID}.ts`;
    const streamUrlProxy = Buffer.from(
      `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/` + streamOfID + ".ts",
      "utf-8",
    ).toString("base64");
    // const videoUrl = `http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`;

    // videoPlayer
    //   .playVideo(
    //     localStorage.getItem("vpn") == "on"
    //       ? localStorage.getItem("proxy_url")
    //         ? localStorage.getItem("proxy_url") + streamUrlProxy
    //         : videoUrl
    //       : videoUrl,
    //     context,
    //   )
    //   .then(() => {
    //     console.log("Video is now playing.");
    //     setIsBuffering(false);
    //     setIsVideoPlaying(true);
    //   })
    //   .catch(error => {
    //     console.error("Error playing video:", error);
    //     setIsVideoPlaying(false);
    //   });
  }

  // createEffect(() => {
  //   const current = currentStream();
  //   if (current) {
  //     onEnterTrailer("background");
  //   }
  // });

  const resetOSDTimeout = () => {
    clearTimeout(timeoutId);

    // Reset the OSD visibility timer
    timeoutId = setTimeout(() => {
      setShowOSD(false);
      setOsdQueue([]); // Clear the queue after timeout
    }, 6000);
  };

  // Cleanup timeout on component unmount
  onCleanup(() => {
    clearTimeout(timeoutId);
  });

  onCleanup(() => {
    clearTimeout(timeoutId);
  });

  createEffect(() => {
    if (liveChannel() && data() && data().length > 0) {
      resetOSDTimeout();
    }
  });

  let channelMenuRef;

  return (
    <View
      onDown={decrementIndex}
      onUp={incrementIndex}
      onEscape={() => {
        onCustomEscape();
      }}
      autofocus
      onEnter={() => {
        onCustomEscape();
      }}
    >
      {/* <Show when={channelMenu()}>
        <ChannelMenu isFromLiveTv={true} />
      </Show> */}

      <Show when={liveChannel() && data() && data().length > 0 && showOSD()}>
        <OSD channel={liveChannel()} />
      </Show>
      <Show when={isBuffering()}>
        <View x={800} y={400}>
          <LoadingIndicator />
        </View>
      </Show>
    </View>
  );
};

export default LiveTvPage;